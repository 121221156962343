import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';
import {
    CommunicationMethod,
    convertRawDetailResponseToStatusDetail,
    convertRawPositiveConfirmationDisbursementLevelToEnum,
    createFundingExeptionTrackerFromTags,
    createNameStringFromApplicant,
    FundingExceptionTracker,
    FundingRequestRowViewModel,
} from '@components/funding-request-summary-table/models/funding-request-table.models';
import { Status } from '@components/funding-request-summary-table/models/status.model';
import { SetCallDateTimePanelData } from '@components/update-funding-request/update-funding-request-call-time/update-funding-request-call-time.component';
import { CallType, RoutingResolvers } from '@constants';
import { FundingRequestService } from '@services/funding-request/funding-request.abstract';
import { FundingRequest } from '@services/funding-request/models/funding-request.model';
import { convertRawStatusResponseToStatus } from '@services/funding-request/models/response-status-converters';
import { SidePanelContent } from '@services/side-panel/models/side-panel.model';
import { SidePanelService } from '@services/side-panel/side-panel.service';
import { UpdateFundingRequestService } from '@services/update-funding-request/update-funding-request.service';
import { Moment } from 'moment';
// ng lint is disabled to avoid no-duplicate-imports error
// eslint-disable-next-line no-duplicate-imports
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { ConstantsOverviewDetailsSection, ConstantsOverviewPage } from '../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_DetailPages';
import { ConstantsObjectsLabelValuePair } from '../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_Objects';
import { createFundingRequestRowViewModelFromFundingRequestResponse } from '../funding-request-summary-table/models/funding-request-table.models';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'eb-funding-request-details',
    templateUrl: './funding-request-details.component.html',
    styleUrls: ['./funding-request-details.component.scss'],
})
export class FundingRequestDetailsComponent implements OnInit {
    @ViewChild('sideNav', { static: true }) public sideNav;
    readonly millisecondsInAnHour: number = 1000 * 60 * 60;

    public fundingRequestDetails: FundingRequest = {} as FundingRequest;

    public callType: typeof CallType = CallType;

    public dataSource: MatTableDataSource<FundingRequestRowViewModel>;

    public overviewSelected = true;

    public authorizationNum: string;

    public displayedComponent: SidePanelContent;

    public sidePanelContent: typeof SidePanelContent = SidePanelContent;

    public fundingRequestModel: FundingRequestRowViewModel = {} as FundingRequestRowViewModel;

    public selectedTab = 0;

    // E2E TESTS
    public constantsOverviewPage: typeof ConstantsOverviewPage = ConstantsOverviewPage;
    public constantsOverviewDetailsSection: typeof ConstantsOverviewDetailsSection = ConstantsOverviewDetailsSection;
    public constantsObjectsLabelValuePair: typeof ConstantsObjectsLabelValuePair = ConstantsObjectsLabelValuePair;

    // Locked Panel
    public requestLocked: boolean;

    // LEDGER #1
    public applicationNumber: string;
    public positiveConfirmation: string;
    public contractor: string;
    public applicantFullName: string;
    public servicingWeb: string;

    // LEDGER #2
    public amount: number;
    public dealerFee: number;
    public netProceeds: number;

    // LEDGER #3
    public createdDateTime: Moment;
    public lastCallDateTime: Moment;
    public nextCallDateTime: Moment;
    public commitmentExpirationDate: Moment;
    public authorizationStatus: string;
    public changeStatusAllowed: boolean;
    public statusDetail: string;

    // LEDGER #4
    public contractorDba: string;
    public spectrumAccountNumber: string;
    public communicationMethod: CommunicationMethod;
    public optedOutOfSms: string;
    public contractorFullName: string;
    public disbursementNumber: number;
    public disbursementDescription: string;
    public applicantPhoneNumber: string;
    public contractorPositiveConf: string;
    public contractorPositiveConfTrigger: string;
    public statusDetails: string;
    public applicantAddress: string;
    public coApplicantAddress: string;
    public coApplicantFullName: string;
    public communicationPhoneNumber: string;
    public updateCallDateTimeSubscription: Subscription;
    public fundingExceptions: FundingExceptionTracker;
    public fundingRequestAuthorizationDuration: number;
    public finalDisbursement: string;
    public fundingNoticeMethod: string;

    constructor(
        public dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private sidePanelService: SidePanelService,
        private updateFundingRequestService: UpdateFundingRequestService,
        private fundingRequestService: FundingRequestService,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {
        this.servicingWeb = this.environment.servicingWeb;
    }

    public get showCoApplicantAddress(): boolean {
        return (
            !!this.fundingRequestDetails.coApplicant &&
            (!!this.fundingRequestDetails.coApplicant.address1 ||
                !!this.fundingRequestDetails.coApplicant.address2 ||
                !!this.fundingRequestDetails.coApplicant.city ||
                !!this.fundingRequestDetails.coApplicant.state ||
                !!this.fundingRequestDetails.coApplicant.zipCode)
        );
    }

    public ngOnInit(): void {
        const routeData = this.activatedRoute.snapshot.data;
        const queryParams = this.activatedRoute.snapshot.queryParams;

        // SIDE PANEL CONFIGURATION
        this.sidePanelService.openCloseSideNav.subscribe((isSidePanelOpen) => {
            if (isSidePanelOpen) {
                this.openDrawer();
            } else {
                this.closeDrawer();
            }
        });

        this.sidePanelService.onSidePanelContentChanged.subscribe((sidePanelParameters) => {
            this.displayedComponent = sidePanelParameters.displayedComponent;
            this.fundingRequestModel = sidePanelParameters.fundingRequestModel;
        });

        this.sideNav.closedStart.subscribe(() => this.sidePanelService.onSideNavOpenClosed.next(false));
        this.sideNav.openedStart.subscribe(() => this.sidePanelService.onSideNavOpenClosed.next(true));

        // Select correct tab based on query parameters
        if (queryParams) {
            this.selectedTab = queryParams.showHistory === 'true' ? 1 : 0;
        }
        // ROUTE DATA CONFIGURATION
        if (routeData && routeData[RoutingResolvers.fundingRequestDetailsPageResolver]) {
            const fundingRequestDetailsRouteData = routeData[RoutingResolvers.fundingRequestDetailsPageResolver];
            this.initFundingRequestDetails(fundingRequestDetailsRouteData);
        }
    }

    public initFundingRequestDetails(fundingRequest: FundingRequest): void {
        this.fundingRequestDetails = fundingRequest;

        this.authorizationStatus = convertRawStatusResponseToStatus(this.fundingRequestDetails.status);

        this.changeStatusAllowed = this.authorizationStatus === Status.hold || this.authorizationStatus === Status.pending || this.authorizationStatus === Status.approved;

        this.requestLocked = this.authorizationStatus === Status.inProcess;

        this.authorizationNum = this.fundingRequestDetails.authorizationNumber;

        // TODO: As per Steve Evers, the loan id will be copied to the application number until we hear back from Sergio.
        this.applicationNumber = this.fundingRequestDetails.applicationNumber;
        this.positiveConfirmation = this.convertPositiveConfirmation(this.fundingRequestDetails.positiveConfirmationLevel, this.fundingRequestDetails.isFinalDisbursement);
        this.contractor = this.fundingRequestDetails.contractor;

        this.applicantFullName = createNameStringFromApplicant(this.fundingRequestDetails.applicant);
        this.coApplicantFullName = createNameStringFromApplicant(this.fundingRequestDetails.coApplicant);
        if (this.coApplicantFullName) {
            this.coApplicantFullName += ' (co-applicant)';
        }

        this.createdDateTime = moment(this.fundingRequestDetails.createdDate);
        this.lastCallDateTime = moment(this.fundingRequestDetails.lastCall);
        this.nextCallDateTime = moment(this.fundingRequestDetails.nextCall);
        this.commitmentExpirationDate = moment(this.fundingRequestDetails.commitmentExpirationDate);
        this.statusDetail = convertRawDetailResponseToStatusDetail(this.fundingRequestDetails.statusDetail);

        this.contractorDba = this.fundingRequestDetails.contractorDba;
        this.spectrumAccountNumber = this.fundingRequestDetails.spectrumAccountNumber;
        this.communicationMethod = this.fundingRequestDetails.applicant.communicationMethod;
        this.optedOutOfSms = this.fundingRequestDetails.optedOutOfSms;
        this.contractorFullName = this.fundingRequestDetails.contractor;
        this.disbursementNumber = this.fundingRequestDetails.disbursementNumber;
        this.disbursementDescription = this.createDisbursementDescription(this.fundingRequestDetails.maxDisbursements, this.fundingRequestDetails.isFinalDisbursement);
        this.fundingRequestAuthorizationDuration = moment.duration(this.fundingRequestDetails.fundingRequestAuthorizationDuration).asHours();
        this.applicantPhoneNumber = this.formatPhoneNumber(this.fundingRequestDetails.applicant.phoneNumber);
        this.communicationPhoneNumber = this.formatPhoneNumber(this.fundingRequestDetails.communicationPhoneNumber);
        this.contractorPositiveConf = this.convertPositiveConfirmation(this.fundingRequestDetails.positiveConfirmationLevel, this.fundingRequestDetails.isFinalDisbursement);

        this.contractorPositiveConfTrigger = this.convertPCTrigger();

        this.applicantAddress = this.formatApplicantAddress(this.fundingRequestDetails);
        this.coApplicantAddress = this.formatCoApplicantAddress(this.fundingRequestDetails);

        this.amount = this.fundingRequestDetails.amount;
        this.dealerFee = this.fundingRequestDetails.dealerFees ? this.fundingRequestDetails.dealerFees : 0;
        this.netProceeds = this.fundingRequestDetails.netProceeds ? this.fundingRequestDetails.netProceeds : 0;

        this.fundingExceptions = createFundingExeptionTrackerFromTags(
            this.fundingRequestDetails.tags,
            fundingRequest.failedToFund,
            fundingRequest.status,
            moment(fundingRequest.reamortizationDate),
            fundingRequest.closedToPostingCode,
            fundingRequest.accountStatus
        );
        this.finalDisbursement = this.fundingRequestDetails.isFinalDisbursement ? 'Yes' : 'No';
        this.fundingNoticeMethod = this.fundingRequestDetails.fundingNoticeMethod;
    }

    public onSetCallDateTimeClicked(callTypeText) {
        let callDateTime: Moment = moment();

        switch (callTypeText) {
            case CallType.next:
                callDateTime = this.nextCallDateTime;
                break;
            case CallType.last:
                callDateTime = this.lastCallDateTime;
                break;
            default:
                return;
        }

        this.updateFundingRequestService
            .openSetCallDateTime({
                editedCallType: callTypeText,
                selectedDateTime: callDateTime,
                selectedFundingRequest: createFundingRequestRowViewModelFromFundingRequestResponse(this.fundingRequestDetails),
            } as SetCallDateTimePanelData)
            .pipe(take(1))
            .subscribe(() => {
                this.fetchAndUpdateFundingRequestDetails();
            });
    }

    public openStatusOverride(): void {
        const rowViewModel = createFundingRequestRowViewModelFromFundingRequestResponse(this.fundingRequestDetails);
        this.updateFundingRequestService
            .openUpdateStatus(rowViewModel)
            .pipe(take(1))
            .subscribe(() => {
                this.fetchAndUpdateFundingRequestDetails();
            });
    }

    public closeDrawer(): void {
        this.sideNav.close();
    }

    public openDrawer(): void {
        this.sideNav.open();
    }

    private convertPCTrigger(): string {
        const pcTrigger = convertRawPositiveConfirmationDisbursementLevelToEnum(this.fundingRequestDetails.positiveConfirmationDisbursementLevel);
        if (pcTrigger == null) {
            return 'N/A';
        }
        return pcTrigger;
    }

    private convertPositiveConfirmation(level: number, isFinalDisbursement: boolean): string {
        if (level == null) {
            return 'N/A';
        }
        return isFinalDisbursement ? 'Level ' + level + ' on Final Disbursement' : 'Level ' + level;
    }

    private createDisbursementDescription(maxDisbursements: number, isFinalDisbursement: boolean): string {
        return `of ${maxDisbursements} allowed ${isFinalDisbursement ? '- final' : ''}`;
    }

    private formatApplicantAddress(fundingRequest: FundingRequest): string {
        const address: string = `${fundingRequest.applicant.address1 || ''}` + '\n' + `${fundingRequest.applicant.address2 || ''}`;

        const cityStateZip: string = `${fundingRequest.applicant.city || ''}, ` + `${fundingRequest.applicant.state || ''} ` + `${fundingRequest.applicant.zipCode || ''}`;

        return `${address}` + '\n' + `${cityStateZip}`;
    }

    // Givent that the applicant structure is flat on the model, it is most simple to copy the above functionality.
    private formatCoApplicantAddress(fundingRequest: FundingRequest): string {
        const coApplicant = fundingRequest.coApplicant;
        const address: string =
            `${!!coApplicant && coApplicant.address1 ? coApplicant.address1 : ''}` + `${!!coApplicant && coApplicant.address2 ? '\n' + coApplicant.address2 : ''}`;

        const cityStateZip: string =
            `${!!coApplicant && coApplicant.city ? coApplicant.city : ''}, ` +
            `${!!coApplicant && coApplicant.state ? coApplicant.state : ''} ` +
            `${!!coApplicant && coApplicant.zipCode ? coApplicant.zipCode : ''}`;

        return `${address}` + '\n' + `${cityStateZip}`;
    }

    private fetchAndUpdateFundingRequestDetails(): void {
        this.fundingRequestService
            .getFundingRequestDetails(this.authorizationNum)
            .pipe(take(1))
            .subscribe((details: FundingRequest) => {
                this.initFundingRequestDetails(details);
            });
    }

    private formatPhoneNumber(phNum: string): string {
        let formattedPhone = '';
        if (phNum !== null) {
            formattedPhone = `(${phNum.slice(0, 3)}) ${phNum.slice(3, 6)}-${phNum.slice(6, 10)}`;
        }
        return formattedPhone;
    }
}
