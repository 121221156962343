<mat-sidenav-container>
  <!-- SIDE PANEL -->
  <mat-sidenav #sideNav position="end">
    <eb-update-funding-request *ngIf="displayedComponent === sidePanelContent.updateFundingRequest"> </eb-update-funding-request>
  </mat-sidenav>

  <mat-sidenav-content>
    <!-- HEADER -->
    <div class="overview-ledger" [attr.data-automation]="constantsOverviewDetailsSection.containerBaseInfo">
      <div class="overview-column double-column">
        <div class="overview-details" [attr.data-automation]="constantsOverviewPage.headerSectionAuthCount">
          <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">Auth #</div>
          <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">{{ authorizationNum }}</div>
        </div>
      </div>

      <div class="overview-column">
        <div class="overview-details" [attr.data-automation]="constantsOverviewDetailsSection.applicationNumber">
          <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">App #</div>
          <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">{{ applicationNumber }}</div>
        </div>
      </div>

      <div class="overview-column">
        <div class="overview-details" [attr.data-automation]="constantsOverviewDetailsSection.applicant">
          <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">Applicant</div>
          <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">
            <div>{{ applicantFullName }}</div>
            <div>{{ coApplicantFullName }}</div>
          </div>
        </div>
      </div>
      <div class="overview-column">
        <div class="overview-details" [attr.data-automation]="constantsOverviewDetailsSection.loanDetails">
          <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label"> </div>
          <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">
            <div>
              <a class="view-application" href="{{ servicingWeb + '/loans/' + fundingRequestDetails.loanId }}" target="_blank" rel="noopener noreferrer">
                <span class="link">View Loan</span>
                <img class="icon" alt="View Loan" src="assets/icons/external.svg" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mat-tab-group class="tabs-container" [selectedIndex]="selectedTab">
      <!-- OVERVIEW CONTENT -->
      <mat-tab label="Overview">
        <div [attr.data-automation]="constantsOverviewDetailsSection.superContainer">
          <eb-request-locked
            *ngIf="requestLocked"
            [fundingRequest]="fundingRequestDetails"
            [attr.data-automation]="constantsOverviewPage.lockedAuthContainer"
          ></eb-request-locked>

          <!-- LEDGER #1 -->
          <div class="overview-ledger" [attr.data-automation]="constantsOverviewDetailsSection.containerMoneyInfo">
            <div class="overview-column">
              <div class="overview-details" [attr.data-automation]="constantsOverviewDetailsSection.requestedAmount">
                <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">Requested Amount</div>
                <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">{{ amount | currency: 'USD':'symbol' }}</div>
              </div>
            </div>

            <div class="overview-column">
              <div class="overview-details" [attr.data-automation]="constantsOverviewDetailsSection.dealerFee">
                <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">Dealer Fee</div>
                <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">{{ dealerFee | currency: 'USD':'symbol' }}</div>
              </div>
            </div>

            <div class="overview-column">
              <div class="overview-details" [attr.data-automation]="constantsOverviewDetailsSection.netProceeds">
                <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">Net Proceeds</div>
                <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">{{ netProceeds | currency: 'USD':'symbol' }}</div>
              </div>
            </div>

            <div class="overview-column">
              <div class="overview-details">
                <div class="overview-label" [attr.data-automation]="constantsOverviewDetailsSection.exceptions">Exceptions</div>
                <div class="overview-value">
                  <eb-funding-exception-icons [fundingExceptions]="fundingExceptions" class="overview-value"></eb-funding-exception-icons>
                </div>
              </div>
            </div>
          </div>

          <!-- LEDGER #2 -->
          <div class="overview-ledger" [attr.data-automation]="constantsOverviewDetailsSection.containerStatusInfo">
            <div class="overview-column">
              <div class="overview-details created-date" [attr.data-automation]="constantsOverviewDetailsSection.created">
                <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">Created Date</div>
                <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">{{ createdDateTime | date:'M/dd/yyyy, h:mm a'}}</div>
              </div>
            </div>

          
            <div class="overview-column">
              <div class="overview-details" [attr.data-automation]="constantsOverviewDetailsSection.lastCall">
                <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">Last Call</div>
                <ng-container *ngIf="lastCallDateTime && lastCallDateTime.isValid(); then showLastCallDateTime; else setLastCallDateTime"> </ng-container>
                <ng-template #showLastCallDateTime>
                  <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">{{ lastCallDateTime | date:'M/dd/yy, h:mm a'}}</div>
                </ng-template>
                <ng-template #setLastCallDateTime>
                  <a class="set-time-text-link" (click)="onSetCallDateTimeClicked(callType.last)" [attr.data-automation]="constantsObjectsLabelValuePair.value">
                    Set Time
                  </a>
                </ng-template>
              </div>
            </div>

            <div class="overview-column">
              <div class="overview-details" [attr.data-automation]="constantsOverviewDetailsSection.nextCall">
                <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">Next Call</div>
                <ng-container *ngIf="nextCallDateTime && nextCallDateTime.isValid(); then showNextCallDateTime; else setNextCallDateTime"> </ng-container>
                <ng-template #showNextCallDateTime>
                  <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">{{ nextCallDateTime | date:'M/dd/yy, h:mm a'}}</div>
                </ng-template>
                <ng-template #setNextCallDateTime>
                  <a class="set-time-text-link" (click)="onSetCallDateTimeClicked(callType.next)" [attr.data-automation]="constantsObjectsLabelValuePair.value">
                    Set Time
                  </a>
                </ng-template>
              </div>
            </div>

            
            <div class="overview-column">
              <div class="overview-details commitment-expiration-date" [attr.data-automation]="constantsOverviewDetailsSection.commitmentExpirationDate">
                <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">Commitment Expiration Date</div>
                <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">{{ commitmentExpirationDate | date:'M/dd/yyyy'}}</div>
              </div>
            </div>

            <div class="overview-column">
              <div class="overview-details" [attr.data-automation]="constantsOverviewDetailsSection.status">
                <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">Status</div>
                <ng-container *ngIf="changeStatusAllowed; then changeAllowed; else changeNotAllowed"> </ng-container>
                <ng-template #changeAllowed>
                  <a class="overview-value change-status-text-link" (click)="openStatusOverride()" [attr.data-automation]="constantsObjectsLabelValuePair.value">
                    {{ authorizationStatus }}
                  </a>
                </ng-template>
                <ng-template #changeNotAllowed>
                  <a [attr.data-automation]="constantsObjectsLabelValuePair.value">
                    {{ authorizationStatus }}
                  </a>
                </ng-template>
              </div>
            </div>

            <div class="overview-column">
              <div class="overview-details" [attr.data-automation]="constantsOverviewDetailsSection.statusDetail">
                <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">Status Detail</div>
                <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">{{ statusDetail }}</div>
              </div>
            </div>
          </div>

          <!-- LEDGER #3 -->
          <div class="overview-ledger" [attr.data-automation]="constantsOverviewDetailsSection.containerOtherInfo">
            <div class="overview-column">
              <div class="overview-details" [attr.data-automation]="constantsOverviewDetailsSection.contractorDBA">
                <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">Contractor DBA</div>
                <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">{{ contractorDba }}</div>
              </div>

              <div class="overview-details" [attr.data-automation]="constantsOverviewDetailsSection.contractorName">
                <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">Contractor Name</div>
                <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">{{ contractorFullName }}</div>
              </div>

              <div class="overview-details" [attr.data-automation]="constantsOverviewDetailsSection.pcLevel">
                <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">PC Level</div>
                <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">{{ contractorPositiveConf }}</div>
              </div>

              <div class="overview-details" [attr.data-automation]="constantsOverviewDetailsSection.pcTrigger">
                <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">PC Trigger</div>
                <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">{{ contractorPositiveConfTrigger }}</div>
              </div>
            </div>

            <div class="overview-column">
              <div class="overview-details" [attr.data-automation]="constantsOverviewDetailsSection.spectrumAccountNumber">
                <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">Account #</div>
                <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">{{ spectrumAccountNumber }}</div>
              </div>

              <div class="overview-details disbursement" [attr.data-automation]="constantsOverviewDetailsSection.disbursement">
                <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">Disbursement</div>
                <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">
                  {{ disbursementNumber | ordinal }} {{ disbursementDescription }}
                </div>
              </div>

              <div class="overview-details finalDisbursement" [attr.data-automation]="constantsOverviewDetailsSection.isFinalDisbursement">
                <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">Final Disbursement?</div>
                <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">{{ finalDisbursement }}
                </div>
              </div>

              <div class="overview-details" [attr.data-automation]="constantsOverviewDetailsSection.timeToRespond">
                <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">Time To Respond</div>
                <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value"> {{ fundingRequestAuthorizationDuration }} Hours</div>
              </div>
            </div>

            <div class="overview-column">
              <div class="overview-details" [attr.data-automation]="constantsOverviewDetailsSection.communicationMethod">
                <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">Communication Method</div>
                <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">{{ communicationMethod | communicationMethod }}<span *ngIf="optedOutOfSms !== null"> (SMS opt out on {{optedOutOfSms | date:'M/dd/yy'}})</span></div>
              </div>

              <div class="overview-details preferred-phone" [attr.data-automation]="constantsOverviewDetailsSection.preferredPhone">
                <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">Preferred Phone</div>
                <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">{{ communicationPhoneNumber }}</div>
              </div>

              <div class="overview-details applicant-phone" [attr.data-automation]="constantsOverviewDetailsSection.applicantPhone">
                <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">Applicant Phone</div>
                <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">{{ applicantPhoneNumber }}</div>
              </div>
              <div class="overview-details" [attr.data-automation]="constantsOverviewDetailsSection.fundingNoticeMethod">
                <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">Funding Notice Method</div>
                <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">{{ fundingNoticeMethod }}</div>
              </div>

            </div>

            <div class="overview-column">
              <div class="overview-details applicant-address" [attr.data-automation]="constantsOverviewDetailsSection.applicantAddress">
                <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">Applicant Address</div>
                <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">{{ applicantAddress }}</div>
              </div>

              <div *ngIf="showCoApplicantAddress" class="overview-details applicant-address" [attr.data-automation]="constantsOverviewDetailsSection.coApplicantAddress">
                <div class="overview-label" [attr.data-automation]="constantsObjectsLabelValuePair.label">Co-applicant Address</div>
                <div class="overview-value" [attr.data-automation]="constantsObjectsLabelValuePair.value">{{ coApplicantAddress }}</div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="History">
        <eb-funding-request-history *ngIf="authorizationNum" [authorizationNumber]="authorizationNum"></eb-funding-request-history>
      </mat-tab>

      <mat-tab label="Communications">
        <eb-message-status-history *ngIf="authorizationNum" [authorizationNumber]="authorizationNum"></eb-message-status-history>
      </mat-tab>

    </mat-tab-group>
  </mat-sidenav-content>
</mat-sidenav-container>
